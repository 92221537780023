type ClosurePageDateProps = {
  closureLabel: string;
};

export default function ClosurePageStatus({
  closureLabel,
}: ClosurePageDateProps) {
  closureLabel = closureLabel.charAt(0).toUpperCase() + closureLabel.slice(1);
  if (closureLabel == "Alert") {
    return (
      <div className="text-md bg-orange-500 rounded inline-block px-2">
        {closureLabel}
      </div>
    );
  }
  if (closureLabel == "Closed") {
    return (
      <div className="text-md bg-red-500 rounded inline-block px-2">
        {closureLabel}
      </div>
    );
  }
  if (closureLabel == "Reopened") {
    return (
      <div className="text-md bg-green-500 rounded inline-block px-2">
        {closureLabel}
      </div>
    );
  }
}
