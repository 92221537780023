
type ContentMapProps = {
  label: string;
  index: number;
    closureID: string | undefined;
};


const ContentMap = ({label, index, closureID}: ContentMapProps) => {
  const pathname = 'https://closures.pcta.org/closure';
  const url = `${pathname}/${closureID}/map/${index}`;
  let additionalStyles = ' px-2 py-1 rounded bg-gray-500 hover:bg-gray-400 flex-1';
  let fontStyles = 'text-sm md:text-md text-gray-100 dark:text-gray-300';
  if(index == 0) {
    fontStyles = 'text-white text-lg md:text-xl';
    additionalStyles = ' px-2 py-1 rounded bg-sky-600 hover:bg-sky-500 w-60 mx-auto my-2 text-center';
  }
  
  return (
    <a href={url} target="_blank">
      
        
        <div className={`${fontStyles}${additionalStyles}`}>{label}</div>
      
    </a>
  );
};
export default ContentMap;