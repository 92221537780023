//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { ref, getDownloadURL } from "firebase/storage";
import { useStorageSource } from "@firecms/core";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-thin-svg-icons/faFilePdf";
type ContentPDFProps = {
  value: { label: string; file: string };
};



export default function ContentPDF({ value }: ContentPDFProps) {
  
  const storageSource = useStorageSource();
  const [PDFURL, setPDFURL] = useState<string | null>(null);

  useEffect (() => {
    storageSource.getDownloadURL(value.file).then((data) => {
      setPDFURL(data.url);
      
    });
  }, []);

  return PDFURL != null ? (
    <a href={PDFURL} target="_blank">
      <div className="my-2 max-w-3xl">
        <div className="flex flex-row gap-4 items-center">
          <FontAwesomeIcon
            icon={faFilePdf}
            size={"3x"}
            color={"#FF0909"}
          />
          <div className="text-xl md:text-2xl text-blue-500 hover:text-blue-400 dark:hover:text-blue-500 hover:underline">
            {value.label}
          </div>
        </div>
      </div>
    </a>
  ) : (
    ""
  );
}