import { useStorageSource } from "@firecms/core";
import { useEffect, useState } from "react";
import { getImageSize } from 'react-image-size';

type ContentImageProps = {
  value: string;
};

export default function Image({ value }: ContentImageProps) {
  //const imgUrl = await getDownloadURL(ref(storage, value));
  //const imgMeta = await probe(imgUrl);
  const storageSource = useStorageSource();
  const [imgURL, setImgURL] = useState<string | null>(null);
  const [imgMeta, setImgMeta] = useState<probeResult | null>(null);
  useEffect (() => {
    storageSource.getDownloadURL(value).then((data) => {
      setImgURL(data.url);
      getImageSize(data.url).then((size) => {
        setImgMeta(size);
      });
    });
  }, []);

  return imgURL && imgMeta ? (
    <img
      src={imgURL}
      className="my-4 h-auto max-w-full mx-auto"
      width={imgMeta.width}
      height={imgMeta.height}
      alt=""
    />
  ) : (
    ""
  );
}