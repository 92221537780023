//import React from "react";
import {
  FieldHelperText,
  FieldProps,
  //TextField,
  //useModeController,
} from "@firecms/core";
import { useState, useEffect } from "react";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Alert } from "@firecms/ui";
interface LabelWithIconProps {
  title?: string;
  required?: boolean;
}

function LabelWithIcon({ title, required }: LabelWithIconProps) {
  return (
    <span
      className={`font-medium text-sm`}
    >
      {(title ?? "") + (required ? " *" : "")}
    </span>
  );
}
export default function MapboxLocation({
  property,
  value,
  setValue,
  setFieldValue,
  customProps,
  touched,
  includeDescription,
  showError,
  error,
  isSubmitting,
  context, // the rest of the entity values here
  ...props
}: FieldProps<{}>) {
  const [marker, setMarker] = useState({
    latitude: value.lat ? value.lat : 39.78915298789167,
    longitude: value.lng ? value.lng : -121.3942389384216,
  });
  const [visible, setVisible] = useState(false);
  const [arcgisURL, setArcgisURL] = useState(`https://pcta.maps.arcgis.com/apps/instant/sidebar/index.html?appid=f7f52a350442403e895e59cf315a4295&center=${marker.longitude},${marker.latitude}&level=10`);


  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => {
        setVisible(false);
      }, 4000);
      return () => clearTimeout(timeout);
    }
  }, [visible]);
  //const { mode } = useModeController();
  //const backgroundColor = customProps?.color ?? (mode === "light" ? "#eef4ff" : "#16325f");
  return (
    <>
      <LabelWithIcon title={property.name} required={property.validation?.required} />
      <Map
        reuseMaps={true}
        mapboxAccessToken="pk.eyJ1IjoiYnJ5emVuIiwiYSI6ImNscGlvMXJxbzAxNDEya291ZGNoNDhia3oifQ.V02EY5UjnS7rhBgazxe7Fg"
        initialViewState={{
          longitude: -121.3942389384216,
          latitude: 39.78915298789167,
          zoom: 4,
        }}
        style={{ width: "100%", height: 400 }}
        mapStyle="mapbox://styles/bryzen/clss3cs15001m01r994hh2uml"
        onClick={(e) => {
          console.log(e)
          setFieldValue("location.lat", e.lngLat.lat);
          setFieldValue("location.lng", e.lngLat.lng);
          setMarker({ latitude: e.lngLat.lat, longitude: e.lngLat.lng });
          setArcgisURL(`https://pcta.maps.arcgis.com/apps/instant/sidebar/index.html?appid=f7f52a350442403e895e59cf315a4295&center=${e.lngLat.lng},${e.lngLat.lat}&level=10`);
        }}
      >
        <Marker
          longitude={marker.longitude}
          latitude={marker.latitude}
        /* anchor="bottom"
        draggable
        onDragStart={onMarkerDragStart}
        onDrag={onMarkerDrag}
        onDragEnd={onMarkerDragEnd} */
        >
          {/* <Pin size={20} /> */}
        </Marker>
      </Map>
      <FieldHelperText
        error={error}
        includeDescription={includeDescription}
        showError={showError}
        property={property}
      />
      <a className="rounded bg-blue-500 p-2 text-white my-2 inline-block hover:text-white hover:bg-blue-600" href={arcgisURL} target="_blank">Add ArcGIS Drawings</a>
      {context.entityId
        ? (
        <>
        
        <div className="rounded inline-block p-1 mx-2 bg-gray-300 dark:bg-gray-700 text-xs">CMS_ID: {context.entityId} <div onClick={() => { navigator.clipboard.writeText(context.entityId); setVisible(true) }} className="rounded bg-blue-500 p-2 text-white mx-2 inline-block hover:text-white hover:bg-blue-600 cursor-pointer">Copy</div></div>
        </>
        )
        : null}
      {visible
        ? <Alert color="base">
          Copied! When adding ArcGIS drawings, paste this into the CMS_ID field.
        </Alert>
        : null}
    </>
  );
}
