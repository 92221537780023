//import React from "react";
import { Entity, EntityValues } from "@firecms/core";
import { Product } from "../../types";
import { Text } from "../../components/Text";
import { HeadingOne } from "../../components/HeadingOne";
import { HeadingTwo } from "../../components/HeadingTwo";
import { HeadingThree } from "../../components/HeadingThree";
import Link from "../../components/Link";
/* import ClosurePageDate from "../components/closurePageDate";
import ClosurePageStatus from "../components/closurePageStatus"; */
import Image from "../../components/Image";
import PDF from "../../components/PDF";
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/pro-thin-svg-icons";
import ContentMap from "../components/contentMap"; */
export function InformationEntityView({
  entity,
  modifiedValues,
}: {
  entity?: Entity<Product>;
  modifiedValues?: EntityValues<Product>;
}) {
  /* const snackbarController = useSnackbarController();

  const onClick = (event: React.MouseEvent) => {
    snackbarController.open({
      type: "success",
      message: `Custom action for ${modifiedValues?.name}`,
    });
  }; */

  const values = modifiedValues ?? {};

  return (
    <div className="flex w-full h-full">
      <div className="m-auto flex flex-col items-center justify-items-center">
        <div className="p-8 md:p-16 flex flex-col gap-4">
          <h1 className="font-extrabold text-3xl md:text-4xl lg:text-5xl text-red-500">
            {values.name}
          </h1>
          

          {values.content.map((e, i) => {
            if (e.type === "text") {
              return <Text key={i} value={e.value} />;
            }
            if (e.type === "heading_1") {
              return <HeadingOne key={i} value={e.value} />;
            }
            if (e.type === "heading_2") {
              return <HeadingTwo key={i} value={e.value} />;
            }
            if (e.type === "heading_3") {
              return <HeadingThree key={i} value={e.value} />;
            }
            if (e.type === "link") {
              return <Link key={i} value={e.value} />;
            }
            if (e.type === "image") {
              return <Image key={i} value={e.value} />;
            }
            if (e.type === "pdf") {
              return <PDF key={i} value={e.value} />;
            }
          })}

          {/* {values && (
            <p
              className={"font-mono"}
              style={{
                color: "#fff",
                padding: "16px",
                fontSize: ".85em",
                borderRadius: "4px",
                backgroundColor: "#4e482f",
              }}
            >
              {JSON.stringify(values, null, 2)}
            </p>
          )} */}
        </div>

        {/* <Button onClick={onClick} color="primary">
          Your action
        </Button> */}
      </div>
    </div>
  );
}
