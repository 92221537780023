import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrowser } from "@fortawesome/pro-thin-svg-icons/faBrowser";
type ContentLinkProps = {
  value: { label: string; url: string };
};

export default function Link({ value }: ContentLinkProps) {
  return (
    <a href={value.url} target="_blank">
      <div className="flex flex-row gap-4 my-2 items-center">
        <FontAwesomeIcon
          icon={faBrowser}
          size={"3x"}
          color={"#FFA000"}
        />
        <div className="text-xl md:text-2xl text-blue-500 hover:text-blue-400 dark:hover:text-blue-500 hover:underline">{value.label}</div>
      </div>
    </a>
  );
}