import { EntityCollection } from "@firecms/core";

export const UsersCollection: EntityCollection = {
  id: "users",
  name: "Users",
  singularName: "User",
  path: "users",
  description: "Registered users in the app/web",
  icon: "person",
  editable: false,
  textSearchEnabled: true,
  exportable: true,
  importable: true,
  permissions: {
    read: true,
    edit: false,
    create: false,
    delete: false,
  },
  properties: {
    firstName: {
      dataType: "string",
      validation: {
        required: true,
      },
      name: "FirstName",
      editable: false,
    },
    lastName: {
      validation: {
        required: true,
      },
      dataType: "string",
      name: "LastName",
      editable: false,
    },
    email: {
      validation: {
        required: true,
      },
      dataType: "string",
      name: "Email",
      editable: false,
    },
    settings: {
      name: "Settings",
      dataType: "map",
      editable: false,
      properties: {
        watching: {
          dataType: "array",
          propertyConfig: "multi_references",
          of: {
            path: "trailClosures",
            name: "Watching",
            dataType: "reference",
          },
          name: "Watching",
          editable: false,
        },
      },
    },
  },
  subcollections: [],
};
