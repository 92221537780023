//import React from "react";
import { Entity, EntityValues } from "@firecms/core";
import { Product } from "../../types";
import { Text } from "../../components/Text";
import { HeadingOne } from "../../components/HeadingOne";
import { HeadingTwo } from "../../components/HeadingTwo";
import { HeadingThree } from "../../components/HeadingThree";
import Link from "../../components/Link";
import ClosurePageDate from "../../components/closurePageDate";
import ClosurePageStatus from "../../components/closurePageStatus";
import Image from "../../components/Image";
import PDF from "../../components/PDF";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/pro-thin-svg-icons";
import ContentMap from "../../components/contentMap";
import ContentMapPDF from "../../components/contentMapPDF";
export function TrailClosuresEntityView({
  entity,
  modifiedValues,
}: {
  entity?: Entity<Product>;
  modifiedValues?: EntityValues<Product>;
}) {
  /* const snackbarController = useSnackbarController();

  const onClick = (event: React.MouseEvent) => {
    snackbarController.open({
      type: "success",
      message: `Custom action for ${modifiedValues?.name}`,
    });
  }; */

  const values = modifiedValues ?? {};

  return (
    <div className="flex w-full h-full max-w-7xl">
      <div className="m-auto flex flex-col items-center justify-items-center">
        <div className="p-8 md:p-16 flex flex-col gap-4">
          <h1 className="font-extrabold text-3xl md:text-4xl lg:text-5xl text-red-500">
            {values.name}
          </h1>
          <div className="flex flex-col md:flex-row gap-4 mt-4 justify-between rounded">
            <div className="flex-1 flex-col gap-2 p-4 bg-slate-200 dark:bg-slate-600 rounded">
              <div className="w-fit text-xl text-gray-700 dark:text-gray-200 font-bold">
                {values?.region}
              </div>

              <ClosurePageDate
                closureDate={values?.created}
                closureLabel="Published"
              />
              <ClosurePageDate
                closureDate={values?.updated}
                closureLabel="Updated"
              />
              <ClosurePageStatus closureLabel={values?.closureStatus} />
            </div>
            <div className="flex-1 flex-col gap-2">
              <div className="rounded border-gray-200 bg-slate-200 dark:bg-slate-600">
                <div className="flex flex-row gap-4 items-center justify-center bg-[#007377] px-2 py-2 rounded">
                  <FontAwesomeIcon
                    icon={faMapLocationDot}
                    size={"2x"}
                    className="text-teal-100"
                  />
                  <b className="text-2xl text-center block text-white dark:text-gray-200">
                    Maps
                  </b>
                </div>
                <ContentMap
                  label="PCT Closure Map"
                  index={0}
                  closureID={entity?.id}
                />

                <div className="border-b">
                  <div className="flex flex-row flex-wrap gap-2 justify-center pb-2">
                    {values.maps?.map((map: any, index: number) => {
                      if (map.type == "pdf") {
                        return (
                          <ContentMapPDF
                            key={index}
                            label={map.value.label}
                            pdfRef={map.value.file}
                          />
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="pr-1"><div className="text-right text-xs">online only</div>
                  <div className="flex flex-row flex-wrap gap-2 justify-center pb-2">
                    {values.maps?.map((map: any, index: number) => {
                      let count = 0;
                      if (map.type == "link") {
                        count++;
                        return (
                          <ContentMap
                            key={index}
                            label={map.value.label}
                            index={count}
                            closureID={entity?.id}
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {values.content.map((e, i) => {
            if (e.type === "text") {
              return <Text key={i} value={e.value} />;
            }
            if (e.type === "heading_1") {
              return <HeadingOne key={i} value={e.value} />;
            }
            if (e.type === "heading_2") {
              return <HeadingTwo key={i} value={e.value} />;
            }
            if (e.type === "heading_3") {
              return <HeadingThree key={i} value={e.value} />;
            }
            if (e.type === "link") {
              return <Link key={i} value={e.value} />;
            }
            if (e.type === "image") {
              return <Image key={i} value={e.value} />;
            }
            if (e.type === "pdf") {
              return <PDF key={i} value={e.value} />;
            }
          })}

          {/* {values && (
            <p
              className={"font-mono"}
              style={{
                color: "#fff",
                padding: "16px",
                fontSize: ".85em",
                borderRadius: "4px",
                backgroundColor: "#4e482f",
              }}
            >
              {JSON.stringify(values, null, 2)}
            </p>
          )} */}
        </div>

        {/* <Button onClick={onClick} color="primary">
          Your action
        </Button> */}
      </div>
    </div>
  );
}
