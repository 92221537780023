type ClosurePageDateProps = {
  closureDate: number;
  closureLabel: string;
};

export default function ClosurePageDate({
  closureDate,
  closureLabel,
}: ClosurePageDateProps) {
  let date = new Date(closureDate).toLocaleString();
  return (
    <div className="text-gray-500 dark:text-gray-400 text-md">
      {closureLabel}: {date}
    </div>
  );
}
