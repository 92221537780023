import { useStorageSource } from "@firecms/core";
import { useEffect, useState } from "react";

type ContentMapPDFProps = {
  label: string;
  pdfRef: string;
};

const ContentMapPDF = ({ label, pdfRef }: ContentMapPDFProps) => {
  const storageSource = useStorageSource();
  const [PDFURL, setPDFURL] = useState<string | null>(null);

  useEffect(() => {
    storageSource.getDownloadURL(pdfRef).then((data) => {
      setPDFURL(data.url);
    });
  }, []);

  let additionalStyles = " px-2 py-1 rounded bg-gray-500 hover:bg-gray-400 flex-1";
  let fontStyles = "text-sm md:text-md text-gray-100 dark:text-gray-300";

  return (
    <a href={PDFURL?.toString()} target="_blank">
      <div className={`${fontStyles}${additionalStyles}`}>{label}</div>
    </a>
  );
};
export default ContentMapPDF;
